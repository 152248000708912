<template>
  <div class="position-relative slide clickable-item">
    <div :style="`background-image: url(${taskData.event_image_url})`" class="slide slide-bg position-absolute"></div>
    <ClientOnly>
      <img loading="lazy" v-image :src="taskData.event_image_url" class="slide slide-img position-relative" />
    </ClientOnly>
  </div>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <p v-if="taskData?.event_slug" class="mb-2">
      <router-link :to="{ name: 'events' }">
        <span class="small clickable-item-hov">
          See all Events
        </span>
      </router-link>
      <span class="small clickable-item-hov"> > </span>
      <router-link :to="{ name: 'event-details-preview', params: { slug: taskData.event_slug } }">
        <span class="small clickable-item-hov">{{ taskData.event_title }}</span>
      </router-link>
      <span class="small clickable-item-hov"> > </span>
      <router-link to="#">
        <span class="small clickable-item-hov">{{ taskData.name }}</span>
      </router-link>
    </p>
    <h1 class="title py-0 my-0">{{ taskData.name }}</h1>
    <div class="my-4">
      <p v-if="taskStatus === 'expired'">Submission period has ended</p>
      <ion-button v-else-if="taskData.starts_at || taskData.ends_at" class="end-btn mx-0"
        :class="{ 'text-font': showRefreshTimer(taskData) }"
        :color="dateHasPassed(taskData.starts_at) ? (showRefreshTimer(taskData) ? 'tertiary' : 'primary') : 'medium'">
        <i class="ti-time icon" />
        <div v-if="dateHasPassed(taskData.starts_at)">
          <vue-countdown v-if="computedEndsAt()" v-slot="{ days, hours, minutes, seconds }"
            :time="formatFromNowInMilliseconds(computedEndsAt())">
            <span><span v-if="showRefreshTimer(taskData)">Restarts in </span><span v-else>Ends in </span><span
                v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span><span v-if="!days">{{ minutes }}m
              </span><span v-if="!days && !hours">{{ seconds }}s</span></span>
          </vue-countdown>
        </div>

        <div v-else>
          <!-- Upcoming -->
          <vue-countdown v-if="taskData?.starts_at" v-slot="{ days, hours, minutes, seconds }"
            :time="formatFromNowInMilliseconds(taskData.starts_at)">
            <span>Starts in <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span><span
                v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span></span>
          </vue-countdown>
        </div>
      </ion-button>
    </div>
    <div v-if="taskData.description" class="d-flex align-items-center mt-2">
      <i class="ti-info prewrap-icon" />
      <strong class="text-primary" style="font-size: 22px;">How to participate?</strong>
    </div>
    <div v-if="taskData.description" class="prewrap mb-3 mt-2" v-html="taskData.description" />
    <div v-if="!submitDisabled && taskData?.name" class="submission-form">
      <div
        v-if="!(taskData?.action_url || '').includes('?quest=ar') && !(taskData?.action_url || '').includes('?quest=cr') && !(taskData.action_url || '').includes('?quest=oc')"
        class="my-2">
        <strong style="font-size: 22px;">Your Submission</strong>
      </div>
      <div>
        <div v-if="isInteractiveTask">
          <div v-for="(i, index) in requiredTemplateInputs.characterCount" :key="index" class="mt-1">
            <ion-label class="bold" mode="md" for="`cpicker-${i}`">Character {{ i }}</ion-label>
            <div class="my-2">
              <div class="mt-2">
                <div class="my-2">{{ selectedCharacterNames?.[index] }}</div>
              </div>
              <div class="d-flex align-items-center">
                <ion-button @click="openCharSelectionModal(index)">Choose</ion-button>
                <div class="close-icon text-black ml-4">
                  <i class="ti-close" />
                </div>
                <span class="text-black reset" :disabled="!newSubmission.extra?.characters?.[index]" color="medium"
                  @click="resetSelectedCharacter(index)">
                  <span class="no-select clickable-item">Reset</span>
                </span>
              </div>
              <div class="mt-2">
                <div class="my-2" v-if="selectedCharacterNames?.[index]">You picked <strong>{{
                  selectedCharacterNames?.[index] }}</strong></div>
              </div>
            </div>
          </div>
        </div>

        <ion-label v-if="isInteractiveTask" for="input-1">
          <span class="bold"> {{ userResponseFieldLabels?.[0] }}</span>
        </ion-label>
        <ion-textarea
          v-if="(taskData.response_type === 'T' && !isInteractiveTask) || (taskData.response_type === 'T' && isInteractiveTask && userResponseFieldLabels.length >= 1)"
          v-model="newSubmission.submission_text" rows="6" class="c-textarea"
          :placeholder="isInteractiveTask && userResponseFieldLabels?.length ? `${userResponseFieldLabels[0]}` : 'Your submission'" />

        <div v-if="taskData.response_type === 'C'">
          <div v-if="!isInteractiveTask">
            <div class="d-flex align-items-center">
              <ion-button @click="openCharSelectionModal(0)">Choose</ion-button>
              <div class="close-icon text-black ml-4">
                <i class="ti-close" />
              </div>
              <span class="text-black reset" :disabled="!newSubmission.submission_character" color="medium"
                @click="resetNewSubmission">
                <span class="no-select clickable-item">Reset</span>
              </span>
            </div>
          </div>

          <div v-if="newSubmission.submission_image_url">
            <p>Your submission (Preview)</p>
            <img loading="lazy" :src="newSubmission.submission_image_url" class="img-preview">
          </div>
        </div>

        <div
          v-if="(taskData.response_type === 'C' && !isInteractiveTask && !isEmpty(newSubmission.submission_character))">
          <Grid :scrollSm="false" :sm="2">
            <CharacterCard :character="selectedCharacters[0]" class="grid-item" />
          </Grid>
        </div>

        <div v-if="isInteractiveTask && requiredTemplateInputs.userResponseCount">
          <div v-for="(i, index) in requiredTemplateInputs.userResponseCount - 1" :key="index" class="mt-2">
            <ion-label :for="`input-${i + 1}`">
              <span class="bold"> {{ userResponseFieldLabels?.[i] }}</span>
            </ion-label>
            <ion-textarea v-model="newSubmission.extra.user_responses[i - 1]" rows="6" class="c-textarea"
              :placeholder="`${userResponseFieldLabels?.[i]}`" />
          </div>
        </div>
      </div>
      <div v-if="taskData.response_type === 'I'">
        <div class="d-flex mb-4 align-items-center">
          <UploadForm class="clickable-item" @uploaded="changeImage">
            <ion-button class="uploader">
              <i class="ti-import mr-1" style="transform: rotate(180deg);" />
              Upload</ion-button>
          </UploadForm>
          <div class="close-icon text-black ml-4">
            <i class="ti-close" />
          </div>
          <span class="text-black reset" :disabled="!newSubmission.submission_image_url" color="medium"
            @click="resetNewSubmission">
            <span class="no-select clickable-item">Reset</span>
          </span>
        </div>
        <div v-if="newSubmission.submission_image_url">
          <p>Your submission (Preview)</p>
          <img loading="lazy" :src="newSubmission.submission_image_url" class="img-preview">
        </div>
      </div>

      <br />
      <div v-if="taskData.is_response_publishable" class="pb-4">
        <p>
          <ion-checkbox v-model="newSubmission.is_public" class="no-select" label-placement="end">Let others see your
            response</ion-checkbox>
        </p>
        <p>
        <div @click="() => (changeColor = true)">
          <ion-checkbox v-if="newSubmission.is_public" v-model="newSubmission.is_nsfw" class="no-select"
            :disabled="!(user.is_nsfw || user.show_nsfw)" label-placement="end"><ion-label>Mark response as Mature <a
                @click.stop target="_blank"
                href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo">(see
                content guidelines)</a></ion-label></ion-checkbox>

        </div>
        </p>
        <div v-if="!(user.is_nsfw || user.show_nsfw)" class="d-flex align-items-center p-2 my-2" style="height: 30px"
          :style="changeColor ? `background: #ffc409 !important` : ''">
          <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
          <p style="font-size: 10px; color: grey" :style="changeColor ? `font-weight: bold` : ''">
            You must turn on “show mature content” and confirm you’re 18+ in
            <router-link :style="changeColor ? `font-weight: bold` : ''" to="/account/user-settings" target="_blank">
              <strong class="clickable-item-hov">Content Settings</strong>
            </router-link>
            to share mature content
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <ion-button v-if="taskData && taskData.response_type !== 'A' && get(user, 'is_email_verified', true)"
          class="inline-button submit-button text-white" :disabled="submitDisabled" @click="makeSubmission">
          <span class="m-3 no-select">Submit</span>
        </ion-button>
        <VerificationButton actionName="Submit" v-else-if="!get(user, 'is_email_verified', true)" />
        <a v-else-if="!isCustomEvent && isAuthenticated" :href="taskData.action_url">
          <ion-button class="inline-button submit-button text-white">
            <span class="m-3 no-select">Start</span>
          </ion-button>
        </a>
      </div>
    </div>
    <div v-else-if="!isAuthenticated">
      <h5><router-link to="/signup">Sign in</router-link> to participate in quests and events!</h5>
    </div>
    <div v-if="isCustomEvent && isAuthenticated">
      <ion-button v-if="(taskData?.action_url || '').includes('?quest=cr')"
        class="inline-button submit-button text-white" @click="openCharacterRoulette">
        <span class="m-3 no-select">Start</span>
      </ion-button>
      <ion-button v-else-if="(taskData?.action_url || '').includes('?quest=ar')"
        class="inline-button submit-button text-white" @click="openArtRoulette">
        <span class="m-3 no-select">Start</span>
      </ion-button>
      <router-link v-else
        :to="(taskData.action_url || '').includes('?quest=oc') ? { name: 'home', query: { event: 'decoration-fest', id: taskData.id } } : taskData.action_url!">
        <ion-button class="inline-button submit-button text-white">
          <span class="m-3 no-select">Start</span>
        </ion-button>
      </router-link>
    </div>
    <div v-if="submissions.length > 0">
      <h4 class="bold">
        Your <span v-if="submissions.length === 1">submission</span><span v-else>submissions</span>
        <span>&nbsp;({{ submissions.length }}<span
            v-if="taskData.per_user_submission_limit && submissions.length <= taskData.per_user_submission_limit"> of {{
              taskData.per_user_submission_limit }} allowed</span>)</span>
        &nbsp;<ion-badge class="completed" color="tertiary" title="Done!"><i class="ti-check" /></ion-badge>
      </h4>
      <div v-if="!isCustomEvent">
        <div v-for="s, subIndex in submissions" :key="subIndex" class="mt-3">
          <p class="mt-3 mb-1"><span class="small gray">{{ formatTimeAgo(s.created) }}</span></p>
          <p v-if="submissionIsInteractionResponse(s) && (s.interaction_response || s.interaction_responses?.length)">
          <p v-if="s.extra?.characters?.length"><span class="bold">Characters: </span>{{
            formatCharacterList(matchingInteractionResponse(s, subIndex, false).characters_serialized) }}</p>
          <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(s, subIndex, false).characters_serialized)[0] }}</p>
          <p v-for="(u, index) in s.extra?.user_responses" :key="index">
          <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(s, subIndex, false).characters_serialized)[index + 1] }}
          </p>{{ u }}
          </p>
          <div v-if="matchingInteractionResponse(s, subIndex, false).form_response">
            <div class="q-response p-3">
              <h5 class="bold mt-0">Quest Outcome</h5>{{ matchingInteractionResponse(s, subIndex, false).form_response }}
            </div>
          </div>
          <p v-else class="pt-2">
            <!-- Tell the user to come back later -->
            <vue-countdown v-slot="{ days, hours, minutes, seconds }"
              :time="formatFromNowInMilliseconds(matchingInteractionResponse(s, subIndex, false).notify_at)" @end="responseIsReady">
              <span>
                <span class="bold">You've done your part!&nbsp;</span><span>Come back in&nbsp;</span>
                <span v-if="days">{{ days }} day<span v-if="days > 1">s</span></span>
                <span v-if="hours"><span v-if="days">&nbsp;and&nbsp;</span>{{ hours }}<span v-if="days">&nbsp;hour<span
                      v-if="hours > 1">s</span></span></span>
                <span v-if="!days"><span v-if="hours">:</span><span v-if="minutes < 10">0</span>{{ minutes }}</span>
                <span v-if="!days">:<span v-if="seconds < 10">0</span>{{ seconds }}</span>&nbsp;to see what happened.
              </span>
              <p
                v-if="formatFromNowInMilliseconds(matchingInteractionResponse(s, subIndex, false).notify_at) <= 0 && !showResponse[subIndex]">
                <span class="clickable-item-hov" @click="refreshData">Refresh</span> this page to see how things
                went!<br>(If you have refreshed and still see this message, please contact us!)
              </p>
            </vue-countdown>
          </p>
          </p>
          <span v-if="s.submission_text && s.submission_text !== 'Submitted'">{{ s.submission_text }}</span>
          <span v-else-if="s.submission_image_url"><img loading="lazy" class="img-preview"
              :src="s.submission_image_url"></span>
          <div v-else-if="s.submission_characters">
            <Grid :scrollSm="false" :sm="2">
              <CharacterCard v-for="sc in s.submission_characters" :character="sc" class="grid-item" />
            </Grid>
          </div>
        </div>
      </div>
      <div v-if="showFeedbackSection">
        <div v-if="askForFeedback === true" class="mt-2 w-100 request-feedback flex-column">
          <h6 class="bold">How disappointed would you be if we removed this type of quest?</h6>
          <FeedbackRating :task="taskData?.id" :range="5" label-min="Not disappointed at all"
            label-max="Very disappointed" @submit="onSubmittedFeedback" />
        </div>
        <div v-else-if="askForFeedback === false" class="mt-4">Thank you for your feedback!</div>
      </div>
    </div>
    <div v-if="previousSubmissions.length > 0">
      <h4 class="bold">
        Your Past Submissions<span>&nbsp;({{ previousSubmissions.length }})</span>
        <div class="mt-1"><ion-button @click="togglePreviousSubmissions">
          <span v-if="showingPreviousSubmissions">Hide</span>
          <span v-else>Show</span>
        </ion-button></div>
      </h4>
      <div v-if="!isCustomEvent && showingPreviousSubmissions">
        <div v-for="p, subIndex in previousSubmissions" :key="'p' + subIndex" class="mt-3">
          <p class="mt-3 mb-1"><span class="small gray">{{ formatTimeAgo(p.created) }}</span></p>
          <p v-if="submissionIsInteractionResponse(p) && (p.interaction_response || p.interaction_responses)">
          <p v-if="p.extra?.characters?.length"><span class="bold">Characters: </span>{{
            formatCharacterList(matchingInteractionResponse(p, subIndex, true).characters_serialized) }}</p>
          <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(p, subIndex, true).characters_serialized)[0] }}</p>
          <p v-for="(u, index) in p.extra?.user_responses" :key="index">
          <p class="bold">{{ getPastResponseFieldLabels(matchingInteractionResponse(p, subIndex, true).characters_serialized)[index + 1] }}
          </p>{{ u }}
          </p>
          <div v-if="matchingInteractionResponse(p, subIndex, true).form_response">
            <div class="q-response p-3">
              <h5 class="bold mt-0">Quest Outcome</h5>{{ matchingInteractionResponse(p, subIndex, true).form_response }}
            </div>
          </div>
          <p v-else class="pt-2">
            <!-- Tell the user to come back later -->
            <vue-countdown v-slot="{ days, hours, minutes, seconds }"
              :time="formatFromNowInMilliseconds(matchingInteractionResponse(p, subIndex, true).notify_at)" @end="responseIsReady">
              <span>
                <span class="bold">You've done your part!&nbsp;</span><span>Come back in&nbsp;</span>
                <span v-if="days">{{ days }} day<span v-if="days > 1">s</span></span>
                <span v-if="hours"><span v-if="days">&nbsp;and&nbsp;</span>{{ hours }}<span v-if="days">&nbsp;hour<span
                      v-if="hours > 1">s</span></span></span>
                <span v-if="!days"><span v-if="hours">:</span><span v-if="minutes < 10">0</span>{{ minutes }}</span>
                <span v-if="!days">:<span v-if="seconds < 10">0</span>{{ seconds }}</span>&nbsp;to see what happened.
              </span>
              <p
                v-if="formatFromNowInMilliseconds(matchingInteractionResponse(p, subIndex, true).notify_at) <= 0 && !showResponse[subIndex]">
                <span class="clickable-item-hov" @click="refreshData">Refresh</span> this page to see how things
                went!<br>(If you have refreshed and still see this message, please contact us!)
              </p>
            </vue-countdown>
          </p>
          </p>
          <span v-if="p.submission_text && p.submission_text !== 'Submitted'">{{ p.submission_text }}</span>
          <span v-else-if="p.submission_image_url"><img loading="lazy" class="img-preview"
              :src="p.submission_image_url"></span>
          <div v-else-if="p.submission_characters">
            <Grid :scrollSm="false" :sm="2">
              <CharacterCard v-for="sc in p.submission_characters" :character="sc" class="grid-item" />
            </Grid>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="taskData.is_response_publishable && (taskData.response_type === 'I' || taskData.response_type === 'T' || taskData.response_type === 'C')"
      class="public-submissions mt-2">

      <InfinitePublicSubmissions :task-id="taskData?.id" :submission-id-to-fetch="notifSubmission"
        @toggleHeader="togglePublicSubmissionHeader" />
    </div>
    <div class="footer-padding"></div>
    <InsertCharacterInfoFieldsModal v-if="isAuthenticated" header="We need more info for this quest!"
      :is-open="isInfoFieldModalOpen" :fields="emptyRequiredTemplateFields" :characters="selectedCharIds"
      @close="closeCharacterInfoFieldsModal" @saveAndClose="saveAndCloseCharacterInfoFieldsModal"
      @directSubmitClose="submitAndCloseCharacterInfoFieldsModal" />
    <CharacterRouletteModal v-if="isAuthenticated" :is-open="isCharacterRouletteOpen" :close="closeCharacterRoulette"
      @dismiss-modal="closeCharacterRoulette" />
    <ArtRouletteModal v-if="isAuthenticated" :is-open="isArtRouletteOpen" :close="closeArtRoulette"
      @dismiss-modal="closeArtRoulette" />
    <event-character-selection-modal v-if="isAuthenticated" :is-open="isSelectingCharModal"
      :index="isSelectingCharIndex" @selected="selectedEventChar" @close="closeCharSelectionModal" />
  </ion-page>
</template>

<script lang="ts">
import axios, { AxiosError } from 'axios';
import { Options, Vue } from 'vue-class-component';
import InfinitePublicSubmissions from './components/InfinitePublicSubmissionsPreview.vue';
import { toast } from '@/shared/native';
import CharacterRouletteModal from '@/shared/modals/CharacterRouletteModal.vue';
import EventCharacterSelectionModal from '@/shared/modals/CharacterSelectionModal.vue';
import ArtRouletteModal from '@/shared/modals/ArtRouletteModal.vue';
import { Character, SiteEventTask, SiteEventTaskSubmission } from '@/shared/types/static-types';
import {
  getEventTask,
  submitToEventTask,
  getUserTaskSubmissions,
  fetchHasSubmittedTaskFeedback,
} from '@/shared/actions/events';
import { getUserCharacters } from '@/shared/actions/characters';
import { dateHasPassed, formatTimeAgo, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import UploadForm from '@/shared/components/upload-form.vue';
import AddCharacterInfoField from '@/shared/components/AddCharacterInfoField.vue';
import FeedbackRating from '@/shared/components/FeedbackRating.vue';
import InsertCharacterInfoFieldsModal from '@/shared/modals/InsertCharacterInfoFieldsModal.vue';
import logger from '@/shared/services/logger';

import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/EventCharacterCard.vue';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import { warningOutline } from 'ionicons/icons';

@Options({
  name: 'EventTaskPage',
  components: {
    UploadForm,
    CharacterCard,
    Grid,
    AddCharacterInfoField,
    FeedbackRating,
    EventCharacterSelectionModal,
    InsertCharacterInfoFieldsModal,
    InfinitePublicSubmissions,
    CharacterRouletteModal,
    ArtRouletteModal,
    VerificationButton
  },
})
export default class EventTaskPage extends Vue {
  formatTimeAgo = formatTimeAgo;
  dateHasPassed = dateHasPassed;
  formatFromNowInMilliseconds = formatFromNowInMilliseconds;
  get = get;
  isCharacterRouletteOpen = false;
  isArtRouletteOpen = false;
  isSelectingCharModal = false;
  isSelectingCharIndex = 0 as number | undefined;
  selectedCharacters = [null];
  selectedCharacterNames = [null]
  public warningOutline = warningOutline;
  public showingPreviousSubmissions = false;

  public get user() {
    const { user } = authStore();
    return user.value;
  }
  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public changeColor = false
  public taskData: SiteEventTask = {
    id: '',
    name: '',
  };

  public submissions: SiteEventTaskSubmission[] = [];
  public previousSubmissions: SiteEventTaskSubmission[] = [];
  public newSubmission: SiteEventTaskSubmission = {
    task: '',
    user: '',
    created: '',
    extra: {
      user_responses: [],
      characters: [],
      input_character_fields: {},
    },
    is_public: true,
    is_nsfw: false,
    username: '',
  };

  public resetSelectedCharacter(index: number) {
    this.newSubmission.extra.characters[index] = null;

    if (this.requiredTemplateInputs?.characterCount) {
      this.selectedCharacters = new Array(this.requiredTemplateInputs.characterCount).fill(null);
      this.selectedCharacterNames = new Array(this.requiredTemplateInputs.characterCount).fill(null);
    } else {
      this.selectedCharacters = [null];
      this.selectedCharacterNames = [null];
    }
  }

  public resetNewSubmission() {
    this.newSubmission = {
      task: '',
      user: '',
      created: '',
      extra: {
        user_responses: [],
        characters: [],
        input_character_fields: {},
      },
      is_public: true,
      is_nsfw: false,
      username: '',
    };
  }

  public isInfoFieldModalOpen = false;
  public taskStatus = '';
  public userCharacters = [] as Character[];
  public showResponse = [] as Boolean[];
  public askForFeedback = null as boolean | null;
  public requiredFields = [];
  public days = 0;
  public hours = 12;
  public minutes = 1;
  public seconds = 1;
  public notifSubmission = '';
  public showPublicSubmissionHeader = false;

  public togglePublicSubmissionHeader(show: boolean) {
    this.showPublicSubmissionHeader = show;
  }

  public openCharacterInfoFieldsModal() {
    // format required fields for the modal
    this.isInfoFieldModalOpen = true;
  }

  public togglePreviousSubmissions() {
    this.showingPreviousSubmissions = !this.showingPreviousSubmissions;
  }

  public computeEndDate = () => {
    const nowDate = new Date();
    if ((this.taskData.action_url || '').includes('?quest=oc')) {
      const nowHours = nowDate.getHours();
      const nowMins = nowDate.getMinutes();

      const startDate = new Date(this.taskData.starts_at);
      const startHours = startDate.getHours();
      const startMins = startDate.getMinutes();

      let totalHours;

      if (nowHours < startHours) {
        totalHours = nowHours + (24 - startHours);
      } else {
        totalHours = nowHours - startHours;
      }

      const totalMinutes = nowMins - startMins + 60;

      const remainingHours = 24 - totalHours;
      const remainingMins = 60 - totalMinutes;

      nowDate.setHours(nowHours + remainingHours);
      nowDate.setMinutes(nowMins + remainingMins);

      return nowDate.toISOString();
    } else {
      return nowDate.toISOString();
    }
  }

  public showRefreshTimer = (task: SiteEventTask) => {
    return task.user_submission_count && task.will_refresh;
  }

  public computedEndsAt = () => {
    return (this.taskData.action_url || '').includes('?quest=oc') ? this.computeEndDate() : this.taskData.ends_at;
  }

  public closeCharacterInfoFieldsModal(chars: Character[]) {
    // update matching characters in this.userCharacters by id field
    if (chars?.length) {
      this.userCharacters = this.userCharacters.map((char) => {
        const matchingChar = chars.find((c) => c.id === char.id);
        if (matchingChar) {
          return matchingChar;
        }
        return char;
      });
    }
    this.isInfoFieldModalOpen = false;
  }

  public saveAndCloseCharacterInfoFieldsModal(chars: Character[]) {
    // update matching characters in this.userCharacters by id field
    if (chars?.length) {
      this.userCharacters = this.userCharacters.map((char) => {
        const matchingChar = chars.find((c) => c.id === char.id);
        if (matchingChar) {
          return matchingChar;
        }
        return char;
      });
    }
    this.isInfoFieldModalOpen = false;
    this.makeSubmission();
  }

  public submitAndCloseCharacterInfoFieldsModal(chars: any) {
    // update matching characters in this.userCharacters by id field
    if (chars) this.newSubmission.extra.input_character_fields = chars;
    this.isInfoFieldModalOpen = false;
    this.makeSubmission();
  }

  public matchingInteractionResponse(submission: SiteEventTaskSubmission, offsetIndex: number, isPastIteration: boolean) {
    if (submission.interaction_response) return submission.interaction_response;
    if (isPastIteration) return submission?.interaction_responses?.[offsetIndex + this.submissions.length];
    return submission?.interaction_responses?.[offsetIndex];
  }

  public get currentTaskId() {
    const router = useRouter();
    return router.currentRoute.value.params.id as string;
  }

  public get isInteractiveTask() {
    return this.requiredTemplateInputs.userResponseCount || this.requiredTemplateInputs.characterCount;
  }

  public get interactiveTaskRequiresMoreCharacterInfo() {
    return this.emptyRequiredTemplateFields?.some((f: string[]) => f.length);
  }

  public get showFeedbackSection() {
    if (this.isInteractiveTask) {
      return this.submissions.some((sub) => sub?.interaction_response || sub?.interaction_responses?.[0].form_response);
    } else {
      return this.submissions.length;
    }
  }

  public recalcTime() {
    if (!this.taskData.ends_at) this.taskStatus = '';
    else if (dateHasPassed(this.taskData.ends_at)) this.taskStatus = 'expired';
    else if (dateHasPassed(this.taskData.starts_at)) this.taskStatus = 'ongoing';
    else this.taskStatus = 'upcoming';
  }

  public get selectedCharIds() {
    // get characters that are in userCharacters and filter out characters already in newSubmission.extra
    const x = this.userCharacters.filter((char) => {
      return this.newSubmission.extra.characters.includes(char.id);
    });
    x.sort((a, b) => {
      return this.newSubmission.extra.characters.indexOf(a.id) - this.newSubmission.extra.characters.indexOf(b.id);
    });
    return x;
  }

  public responseIsReady(i: number) {
    this.showResponse[i] = true;
  }

  public refreshData() {
    this.fetchTaskData();
    this.fetchUserTaskSubmissions();
  }

  public get requiredTemplateInputs() {
    let userResponseCount = 0;
    let characterCount = 0;
    // get number of user responses required
    this.taskData.required_template_fields?.forEach((field: string) => {
      if (field.startsWith('user_response')) {
        userResponseCount++;
      }
    });
    // get higher character number (equal to the number of characters required)
    this.taskData.required_template_fields?.forEach((field) => {
      const match = field.match(/character(\d+)_/);
      if (match) {
        const [, num] = match;
        try {
          characterCount = Math.max(characterCount, parseInt(num));
        } catch (e) { }
      }
    });
    return { userResponseCount, characterCount };
  }

  public _getCharacterById(allCharacters: Character[], id: string) {
    return find(allCharacters, { id });
  }

  public checkForEmptyRequiredTemplateFields(characterArray: Character[]) {
    const out = [] as any[];
    for (let i = 0; i < this.requiredTemplateInputs.characterCount; i++) {
      out.push([]);
    }
    if (!out.length) return [];
    this.taskData.required_template_fields?.forEach((field) => {
      const match = field.match(/character(\d+)_/);
      if (match) {
        const [, num] = match;
        try {
          // debugger;
          const characterIndex = parseInt(num) - 1;
          const fieldArray = field.split('__').slice(1);
          const cid = this.newSubmission.extra.characters[characterIndex];
          let val = this._getCharacterById(characterArray, cid)?.info;
          if (val) {
            fieldArray.forEach((field) => {
              if (val) val = val[field];
            });
            if (!val) {
              out[characterIndex].push(fieldArray);
            }
          }
        } catch (e) {
          logger.error({
            e,
            loc: 'event-task',
            msg: 'Error in emptyRequiredTemplateFields',
            data: this.newSubmission,
          } as any);
        }
      }
    });
    return out;
  }

  public get emptyRequiredTemplateFields() {
    // result looks like
    // [[['backstory'], ['about', 'eyes'], ...], [['backstory'], ['about', 'eyes'], ...]]
    const out = this.checkForEmptyRequiredTemplateFields(this.userCharacters);
    return out;
  }

  _fillCharacterNames(allCharacters: Character[], characters: any[], str: string) {
    const regex = new RegExp(`{character(\\d+)}`, 'g');
    return str.replace(regex, (_, digits) => {
      const index = parseInt(digits) - 1;
      return find(allCharacters, { id: characters[index] })?.info.name || `Character ${index + 1}`;
    });
  }

  _getUserResponseField(allCharacters: Character[], characters: any[], index: number) {
    let out = `Field ${index + 1}`;
    const toFind = new RegExp(`{character(\d+)}`, 'g');
    try {
      out = this.taskData.required_template_field_labels.user_responses[index];
      out = this._fillCharacterNames(allCharacters, characters, out);
    } catch (e) {
      out = out.replace(toFind, `Character ${index + 1}_`);
    } finally {
      return out;
    }
  }

  public openCharSelectionModal(index?: number) {
    this.isSelectingCharModal = true;
    this.isSelectingCharIndex = index;
  }

  public closeCharSelectionModal() {
    this.isSelectingCharModal = false;
    this.isSelectingCharIndex = undefined;
  }

  public selectedEventChar(val: any) {
    const { character, index } = val;
    this.selectedCharacters[index] = character;
    this.selectedCharacterNames[index] = character.info?.name;
    if (this.taskData.response_type === 'C') {
      this.newSubmission.submission_character = character.id;
    }
    if (this.isInteractiveTask && index !== undefined) {
      this.newSubmission.extra.characters[index] = character.id;
    }
    this.closeCharSelectionModal();
  }

  public get userResponseFieldLabels() {
    if (!this.taskData.required_template_field_labels?.user_responses?.length) return []; // if not interactive, return null
    const arr = Array(this.requiredTemplateInputs.userResponseCount)
      .fill(0)
      .map((_: number, index: number) =>
        this._getUserResponseField(this.userCharacters, this.newSubmission.extra.characters, index)
      );
    return arr;
  }

  public getPastResponseFieldLabels(characters: Character[]) {
    const arr = Array(this.requiredTemplateInputs.userResponseCount)
      .fill(0)
      .map((_: number, index: number) =>
        this._getUserResponseField(
          characters,
          characters.map((c) => c.id),
          index
        )
      );
    return arr;
  }

  public submissionIsInteractionResponse(s: SiteEventTaskSubmission) {
    return s.extra?.characters?.length || s.extra?.user_responses?.length;
  }

  public formatCharacterList(characters: Character[]) {
    return characters.map((c: any) => c.info.name).join(', ');
  }

  public async fetchTaskData() {
    try {
      this.taskData = await getEventTask(this.currentTaskId);
    } catch (e: any) {
      if (e.response.status === 404) {
        await toast.show('Page not found.', 'nonative', 'primary');
        return;
      }
      throw e;
    }
    // use userResponseCount-1 because the first user response is in submission_text
    if (this.requiredTemplateInputs.userResponseCount)
      this.newSubmission.extra.user_responses = new Array(this.requiredTemplateInputs.userResponseCount - 1).fill('');
    this.newSubmission.extra.characters = this.requiredTemplateInputs.characterCount
      ? new Array(this.requiredTemplateInputs.characterCount).fill('')
      : [];
    if (this.requiredTemplateInputs.characterCount && this.isAuthenticated) {
      this.userCharacters = await getUserCharacters(this.user?.id, null);
      this.selectedCharacters = new Array(this.requiredTemplateInputs.characterCount).fill(null);
      this.selectedCharacterNames = new Array(this.requiredTemplateInputs.characterCount).fill(null);
    } else {
      this.selectedCharacters = [null];
      this.selectedCharacterNames = [null];
    }
    this.recalcTime();
  }

  public async fetchUserTaskSubmissions() {
    const [currentSubmissions, previousSubmissions] = await Promise.all([
      getUserTaskSubmissions(this.currentTaskId),
      getUserTaskSubmissions(this.currentTaskId, true)
    ]);
    const filteredPreviousSubmissions = previousSubmissions.filter(
      (prevSub: any) => !currentSubmissions.some((currSub: any) => currSub.id === prevSub.id)
    );
    this.submissions = currentSubmissions;
    this.previousSubmissions = filteredPreviousSubmissions;
    this.showResponse = this.submissions.map(
      (sub: any) => !!sub.interaction_response || !!sub.interaction_responses?.some((z: any) => !!z.form_response)
    );
    if (this.submissions.length) this.fetchUserHasSubmittedFeedback();
  }

  public async fetchUserHasSubmittedFeedback() {
    const hasSubmittedFeedback = await fetchHasSubmittedTaskFeedback(this.currentTaskId);
    this.askForFeedback = !hasSubmittedFeedback;
  }

  public changeImage(url: string) {
    this.newSubmission.submission_image_url = url;
  }

  public get isCustomEvent() {
    if (this.taskData?.action_url?.includes('?quest=')) return true;
    return false;
  }

  public get submitDisabled() {
    if (!this.isAuthenticated) return true;
    return (
      this.taskData.per_user_submission_limit && this.taskData.per_user_submission_limit <= this.submissions.length
    );
  }

  public updateCharacter(character: Character) {
    // update the character in the userCharacters array
    this.userCharacters = this.userCharacters.map((c: Character) => {
      if (c?.id === character?.id) {
        return character;
      }
      return c;
    });
  }

  public openFillRequiredFieldsModal() {
    this.isInfoFieldModalOpen = true;
  }

  public async makeSubmission() {
    if (!this.taskData?.id) return;
    if (this.requiredTemplateInputs.characterCount) {
      if (this.newSubmission.extra.characters.some((c: string) => !c)) {
        await toast.show('Please select the required number of characters.', 'nonative', 'danger');
        return;
      }
    }
    if (this.requiredTemplateInputs.userResponseCount) {
      if (this.newSubmission.extra.user_responses.some((r: string) => !r || !r.trim())) {
        await toast.show('All the submission fields need to be filled.', 'nonative', 'danger');
        return;
      }
      this.newSubmission.extra.user_responses = this.newSubmission.extra.user_responses.map((r: string) => r.trim());
    }
    const tempUpdatedCharacterArray = cloneDeep(this.userCharacters);
    for (let i = 0; i < this.newSubmission.extra.characters.length; i++) {
      const cid = this.newSubmission.extra.characters[i];
      const char = this._getCharacterById(tempUpdatedCharacterArray, cid);
      if (char) {
        merge(char.info, this.newSubmission.extra.input_character_fields[cid]);
      }
    }
    if (this.checkForEmptyRequiredTemplateFields(tempUpdatedCharacterArray)?.some((f: string[]) => f.length)) {
      this.isInfoFieldModalOpen = true;
      return;
    }
    if (
      this.taskData.response_type === 'T' &&
      this.isInteractiveTask &&
      this.requiredTemplateInputs.userResponseCount === 0
    ) {
      this.newSubmission.submission_text = 'Submitted';
    }
    if (this.taskData.response_type === 'T' && this.newSubmission.submission_text) {
      this.newSubmission.submission_text = this.newSubmission.submission_text.trim();
    }
    if (this.taskData.response_type === 'T' && !this.newSubmission.submission_text) {
      await toast.show('Submission requires text', 'nonative', 'danger');
      return;
    }
    if (this.taskData.response_type === 'C' && isEmpty(this.newSubmission.submission_character)) {
      await toast.show('Choose character for submission', 'nonative', 'danger');
      return;
    }
    if (this.taskData.response_type === 'I' && !this.newSubmission.submission_image_url) {
      await toast.show('Submission requires an image upload', 'nonative', 'danger');
      return;
    }

    const lim = this.taskData.per_user_submission_limit;
    if (lim && this.submissions.length >= lim) {
      await toast.show('You have already made your submission(s) for this quest.', 'nonative', 'tertiary');
      return;
    }
    try {
      const data = await submitToEventTask(this.currentTaskId, this.newSubmission);
      let message = 'Submission Complete!';
      if (data.is_reviewed) {
        if (data.points_given === 0) {
          message = `Submission complete!`;
        } else {
          message = `Submission complete! Reward has been added.`;
        }
      } else {
        message = `Submission complete! Your response will be reviewed soon.`;
      }
      toast.show(message, 'nonative', 'success');
    } catch (e: unknown) {
      logger.error({
        e,
        loc: 'event-task',
        msg: 'Error in event-task.vue makeSubmission()',
        data: { task: this.taskData, submission: this.newSubmission },
      });
      if (axios.isAxiosError(e)) {
        const eAxiosError = e as AxiosError;
        if ((eAxiosError && !eAxiosError.response) || (eAxiosError.response && !eAxiosError.response.status)) {
          await toast.show('Encountered a connection issue', 'nonative', 'danger');
        }
        if (typeof eAxiosError.response?.data === 'string') {
          toast.show(eAxiosError.response?.data, 'nonative', 'danger');
        } else if (typeof eAxiosError.response?.data === 'object') {
          Object.keys(eAxiosError.response?.data || {}).forEach((key) => {
            toast.show((eAxiosError.response?.data || ({} as any))[key], 'nonative', 'danger');
          });
        } else {
          toast.show('Encountered an unexpected error', 'nonative', 'danger');
        }
      } else {
        toast.show('Encountered an unexpected error', 'nonative', 'danger');
      }
      return;
    }
    this.fetchUserTaskSubmissions();
    this.resetNewSubmission();
  }

  public onSubmittedFeedback() {
    this.askForFeedback = false;
  }

  public openCharacterRoulette() {
    this.isCharacterRouletteOpen = true;
  }

  public openArtRoulette() {
    this.isArtRouletteOpen = true;
  }

  public closeCharacterRoulette() {
    this.isCharacterRouletteOpen = false;
  }

  public closeArtRoulette() {
    this.isArtRouletteOpen = false;
  }

  public mounted() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.fetchTaskData();
    if (this.isAuthenticated) this.fetchUserTaskSubmissions();
    this.notifSubmission = (this.$route.query.s as string) || '';
  }
}
</script>
<style lang="sass" scoped>
.submit-button
  --background: linear-gradient(90deg, #E126FF 0%, rgba(254, 197, 255, 0) 100%), #8928C4 
  --border-radius: 7px
  border-radius: 9px
  margin: 0px
  border: 2px solid #214163

.uploader
  --border-radius: 10px
   
.close-icon
  font-size: 8px
  border: 2px solid
  border-radius: 50px
  padding: 3px
  aspect-ratio: 1/1
  height: 17px
  width: 17px

.icon
  margin-right: 0.5rem
  @media(max-width:419px)
    font-size: 9px
    margin-left: 0.25rem !important
    margin-right: 0.25rem !important

.end-btn
  height: 25px
  --border-radius: 20px
  text-transform: none
  font-size: 13px
  
.slide
  width: 100%
  max-height: 200px
  min-height: 100px
  object-fit: cover

.slide-bg
  background-size: cover
  opacity: 0.3
  height: 100%

.slide-img
  object-fit: contain
  z-index: 99
  
.clickable-item:hover
  opacity: 0.7
.title
  font-weight: bold
.submission-form
  margin-bottom: 1rem
.no-select
  user-select: none !important
.prewrap-icon
  border: 3px solid var(--ion-color-primary)
  color: var(--ion-color-primary)
  border-radius: 5px
  padding: 2px
  font-size: 13px
  margin-right: 0.5rem
  @media(max-width:419px)
    font-size: 12px
    margin-left: 0.25rem !important
    margin-right: 0.25rem !important
.prewrap
  white-space: pre-wrap
  border: 2px solid var(--ion-color-primary)
  border-radius: 12px
  padding: 10px
.img-preview
  max-width: 200px
  max-height: 200px
.reset
  margin-left: 5px
  font-weight: bold
  text-decoration: underline
.completed
  position: relative
  left: 1px
  top: 5px
.cpicker
  max-width: 300px
.clickable-item-hov
  color: var(--ion-color-primary) !important
.q-response
  white-space: pre-wrap
  word-wrap: break-word
  background: rgba(0, 0, 0, 0.05)
  border-radius: 10px
.c-textarea
  border-radius: 10px !important
  border: 2px solid #CCC !important
.request-feedback
  display: flex
  justify-content: center
  align-items: center
  ion-button
    width: 150px
.footer-padding
  height: 75px
</style>
