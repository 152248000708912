<template>
  <div class="update-character-info-field">
    <!-- Save button -->
    <div v-if="infoField.length === 2 && infoField[0] === 'about'">
      <p class="bold mb-1">{{ toTitleCase(infoField[1]) }}</p>
      <InputTextField @save="onSavePressed" />
    </div>
    <div v-else-if="infoField.length === 1">
      <p class="bold mb-1">{{ toTitleCase(infoField[0]) }}</p>
      <InputTextEditorSingle
        v-if="useBodyOnlyKeys.includes(infoField[0])"
        :key="`desc_${rerenderKey}`"
        :used-key="infoField[0]"
        @save="onSavePressed"
      />
      <InputTextFieldSingle v-else :used-key="infoField[0]" @save="onSavePressed" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Character } from '@/shared/types/static-types';
import { addInfoFields } from '@/shared/actions/characters';
import { toast } from '@/shared/native';
import { toTitleCase } from '@/shared/utils/string';
import InputTextEditorSingle from '@/shared/components/InputTextEditorSingle.vue';
import InputTextFieldSingle from '@/shared/components/InputTextFieldSingle.vue';
import { mainStore } from '@/shared/pinia-store/main';

const rerenderKey = ref(0);
const useBodyOnlyKeys = ['description', 'backstory'];
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  infoField: {
    // for example
    // ['backstory'] for info.backstory or
    // ['about', 'eyes'] for info.about.eyes
    // does not support stats yet.
    type: Array as PropType,
    required: true,
  },
});

const emits = defineEmits(['saved']);
const working = ref(false) as Ref<boolean>;

const onSaveSuccess = (c: Character) => {
  emits('saved', c);
};

const onSavePressed = (value: string) => {
  save(value);
};

const isDarkMode = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

watch(isDarkMode, () => {
  rerenderKey.value++;
});

const save = async (updatedValue: string) => {
  if (!updatedValue) return;
  working.value = true;
  const mutableCharacterInfo = { [props.infoField[0]]: null } as any;
  if (props.infoField.length > 1) {
    mutableCharacterInfo[props.infoField[0]] = {} as any;
    mutableCharacterInfo[props.infoField[0]][props.infoField[1]] = updatedValue;
  } else {
    mutableCharacterInfo[props.infoField[0]] = updatedValue;
  }
  try {
    const newCharacter = await addInfoFields(props.id, mutableCharacterInfo);
    if (newCharacter) {
      await toast.show('Saved field', 'nonative', 'primary');
      onSaveSuccess(newCharacter);
    }
  } catch (e) {
    await toast.show('Failed to save field - please try again later', 'nonative', 'danger');
  } finally {
    working.value = false;
  }
};
</script>

<style lang="sass" scoped>
.c-input
  height: 45px !important
  border-radius: 6px !important
  border: 2px solid #CCC !important
  width: 100%
  ::v-deep
    input
      border: 0 !important
</style>
