<template>
  <div class="text">
    <div class="editor">
      <Editor
        v-model="mutatedValue"
        :api-key="`${tinyMCEKey}`"
        :init="{
          height: 350,
          relative_urls: false,
          menu: {
            insert: {
              title: 'Insert',
              items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
            },
          },
          menubar: 'edit view insert format tools table help',
          plugins:
            'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
          toolbar:
            'undo redo | bold italic backcolor |  blocks | fontfamily |  \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
          contextmenu: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: 'bold italic underline strikethrough link',
        }"
        :placeholder="placeholder"
      />
    </div>
    <ion-button color="primary" class="save-button" :disabled="working" @click="save">
      <span>Save</span>
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
const emits = defineEmits(['save']);
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Add character detail',
  },
  working: {
    type: Boolean,
    default: false,
  },
});

const {
  public: { tinyMCEKey },
} = useRuntimeConfig();

const mutatedValue = ref('');
const value = toRef(props, 'value');

onMounted(() => {
  mutatedValue.value = value.value;
});

const save = () => {
  emits('save', mutatedValue.value);
};
</script>

<style lang="sass" scoped>
.text
  .editor
    min-height: 200px !important
    ::v-deep
      .tox
        min-height: 200px !important
        border: 0
        border-radius: 10px
</style>
