<template>
  <div class="text">
    <div class="editor">
      <ion-input v-model="mutatedValue" class="c-input mr-3" type="text" :placeholder="placeholder" />
    </div>
    <ion-button color="primary" class="save-button" :disabled="working" @click="save">
      <span>Save</span>
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
const emits = defineEmits(['save']);
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Add character detail',
  },
  working: {
    type: Boolean,
    default: false,
  },
});

const mutatedValue = ref('');
const value = toRef(props, 'value');

onMounted(() => {
  mutatedValue.value = value.value;
});

const save = () => {
  emits('save', mutatedValue.value);
};
</script>

<style lang="sass" scoped>
.text
  .editor
    ::v-deep
      .tox
        border: 0
        border-radius: 10px
</style>
